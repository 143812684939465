.privacy-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('https://app.reraiseevents.com/background.png');
    background-repeat: repeat-x, repeat-y;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
}
h1, h2, h3, h4, h5, h6 {
    color: #F58556;
}
h1 { font-size: 32px; }
h2 { font-size: 20px; }
p {
    color: white;
    font-size: 18px;
}
