/* https://dribbble.com/shots/15635034-Saifty-GDPR-Settings */
*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --purple-600: #7c3aed;
    --pink-100: #fee2e2;
    --grey-100: #f3f4f6;
    --grey-200: #e5e7eb;
    --grey-300: #d1d5db;
    --grey-400: #9ca3af;
    --grey-500: #6b7280;
    --grey-700: #374151;
    --white: #fff;
}

body {
    margin: 0;
    padding: 30px;
    display: flex;
    height: 100vh;
    align-items: flex-start;
    justify-content: center;
    background-color: #20232A;
    font-family: "Poppins", sans-serif;
    color: var(--grey-700);
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 360px;
    border-radius: 24px;
    padding: 24px;
    background-color: #32363E;
    overflow: hidden;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -1px rgb(0 0 0 / 6%);
    margin-top: 25px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.header-title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
}

.header-image {
    margin-bottom: 15px;
}

.button {
    width: 100%;
    padding: 16px 16px;
    margin: 16px 0;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: var(--white);
    background-color: #f58556;
    -webkit-appearance: none;
    cursor: pointer;
    font-family: inherit;
    transition: background-color 200ms, color 200ms;
}
.button:active {
    background-color: #20232A;
}

.link {
    color: var(--purple-600);
    font-weight: 500;
    text-decoration: none;
}
.link:hover {
    text-decoration: underline;
}

.text {
    font-size: 14px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 16px;
}
.text:not(.link) {
    color: var(--grey-500);
}

.text-center {
    text-align: center;
}

.modal,
.modal-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;
}
.modal-active.modal {
    pointer-events: auto;
}

.modal-backdrop {
    opacity: 0;
    background-color: rgba(8, 2, 29, 0.5);
    transform: translateZ(0);
    transition: opacity 200ms;
}
.modal-active .modal-backdrop {
    opacity: 1;
}

.modal-body {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    width: 100%;
    padding: 24px;
    background: var(--white);
    transform: translateY(100%);
    transition: transform 220ms cubic-bezier(0.33, 1, 0.68, 1);
}
.modal-body::before {
    content: "";
    position: absolute;
    top: 14px;
    height: 4px;
    margin: 0 auto;
    width: 60px;
    border-radius: 2px;
    background-color: var(--grey-200);
}
.modal-active .modal-body {
    transform: translateY(0);
}

.modal-title {
    margin-top: 8px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
}

.settings-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.settings-button {
    min-width: 72px;
    padding: 10px 16px;
    margin: 16px 0;
    border-radius: 16px;
    border: none;
    font-weight: 700;
    font-size: 12px;
    color: var(--grey-500);
    background-color: var(--grey-100);
    -webkit-appearance: none;
    cursor: pointer;
    font-family: inherit;
    transition: background-color 200ms, color 200ms;
}
.settings-button-active {
    background-color: var(--pink-100);
    color: var(--purple-600);
}

.settings-button-icon {
    font-size: 40px;
    text-align: center;
    color: var(--grey-300);
    transform: translate3d(0, 0, 0);
    transition: color 200ms;
}
.settings-button-icon-active {
    color: var(--purple-600);
    transform-origin: center center;
    animation: bounce 500ms ease-in-out;
}
@keyframes bounce {
    0% {
        transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    }
    10% {
        transform: scale3d(1, 0.8, 1) translate3d(0, 0, 0);
    }
    40% {
        transform: scale3d(1, 1, 1) translate3d(0, -12px, 0);
    }
    70% {
        transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    }
    100% {
        transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    }
}

.input {
    position: relative;
    margin: 16px 0;
    font-size: 16px;
}

.input label {
    position: absolute;
    top: 16px;
    left: 20px;
    font-weight: 500;
    color: var(--grey-400);
    pointer-events: none;
    transform-origin: top left;
    transition: transform 200ms;
}

.input input {
    padding: 24px 20px 14px 20px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    border-radius: 8px;
    background: var(--grey-100);
    transition: background-color 160ms;
}
.input input:focus {
    background: var(--grey-200);
}

.input-active label {
    transform: scale(0.7, 0.7) translate(0, -10px);
}

.footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
}
.footer .text {
    margin: 0;
}
